import QRCode from "qrcode";

export const createQrCodeDataUrl = (value: string): Promise<string> => {

	return new Promise<string>((resolve, reject) => {
		QRCode.toDataURL(value, function (error, string) {
			if (error) reject(error)

			resolve(string);
		})
	});
};