import {ethers} from "ethers";
import {KeyPair} from "@tokenscript/attestation/dist/libs/KeyPair";
import {hexStringToBase64, hexStringToUint8} from "@tokenscript/attestation/dist/libs/utils";
import {RPC_CONFIG} from "./constants";

const privKeyInput = document.querySelector("#priv-key") as HTMLTextAreaElement;

declare global {
	interface Window {
		ethereum: any
	}
}

export function getPrivateKeyAsHex(){

	if (!privKeyInput.value)
		throw new Error("Please input private key or refresh the page to use a test key");

	if (!privKeyInput.value.match(/^(0x)?[A-Fa-f0-9]+$/)) {
		console.log("Processing PEM private key")
		// Convert base64/PEM to hex private key
		return KeyPair.privateFromPEM(privKeyInput.value).getPrivateAsHexString();
	}

	return privKeyInput.value;
}

export async function getWallet(chain: number, useMetamask?: boolean){
	const provider = new ethers.providers.StaticJsonRpcProvider(RPC_CONFIG[chain]);

	if (useMetamask){
		return getMetamaskSigner(chain);
	} else {
		return new ethers.Wallet(getPrivateKeyAsHex(), provider);
	}
}

async function getMetamaskSigner(chain: number){

	try {
		await window.ethereum.enable();

		const provider = new ethers.providers.Web3Provider(window.ethereum);

		if ((await provider.getNetwork()).chainId !== chain)
			await provider.send("wallet_switchEthereumChain", [{chainId: "0x" + chain.toString(16)}]);

		return provider.getSigner(0);
	} catch (e){
		console.error(e);
		throw new Error("Connected to wrong chain, please switch the chain to chainId: " + chain + ", error: " + e.message);
	}
}

/*export function getSignerAddress(chain: number){
	const wallet = getWallet(chain);
	return wallet.address;
}*/

export function getPublicKeyConfig(){
	return {"": KeyPair.fromPrivateUint8(hexStringToUint8(privKeyInput.value), "secp256k1")};
}