import {Importer, ImporterField} from "react-csv-importer";
import ReactDOM from "react-dom";
import "react-csv-importer/dist/index.css";
import {
	EasTicketAttestation,
	EasTicketCreationOptions,
	SchemaField
} from "@tokenscript/attestation/dist/eas/EasTicketAttestation";
import {getWallet} from "./wallet";
import Papa from "papaparse"
import {base64toBase64Url} from "@tokenscript/attestation/dist/libs/utils";
import {SchemaConfig} from "./index";
import {createQrCodeDataUrl} from "./util";

type ImportResult = {importedData: any, attestation: any, uid: string, magicLink: string};

const importModal = document.querySelector("#modal") as HTMLDivElement;
const importerDiv = document.getElementById("importer");

const magicLinkBaseField = document.querySelector("#magic-link-base") as HTMLInputElement;
const magicLinkTokenScriptField = document.querySelector("#magic-link-tokenscript") as HTMLInputElement;

importModal.addEventListener("click", (e) => {
	if ((e.target as HTMLElement).id != "modal")
		return;

	closeModal();
});

export async function openModal(schemaConfig: SchemaConfig, staticValues: {[fieldName: string]: string} = {}, includeQr){

	console.log("Static values: ", staticValues);

	const attestations: ImportResult[] = [];

	const wallet = await getWallet(schemaConfig.config.chainId);

	const attestationManager = new EasTicketAttestation(
		{fields : Object.values(schemaConfig.fields)},
		{
			EASconfig: schemaConfig.config,
			signer: wallet
		}
	)

	ReactDOM.render(
		<Importer
			dataHandler={async (rows) => {
				// required, receives a list of parsed objects based on defined fields and user column mapping;
				// may be called several times if file is large
				// (if this callback returns a promise, the widget will wait for it before parsing more data)
				console.log("received batch of rows", rows);

				return generateBulkAttestations(rows, schemaConfig.options, staticValues, attestationManager).then((newAttests) => {
					attestations.push(...newAttests)
				}).catch((e) => {
					console.error(e);
					alert("Error creating attestations: " + e);
					//closeModal();
				})
			}}
			chunkSize={10000} // optional, internal parsing chunk size in bytes
			defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
			restartable={false} // optional, lets user choose to upload another file when import is complete
			onStart={({ file, fields }) => {
				// optional, invoked when user has mapped columns and started import
				// console.log("starting import of file", file, "with fields", fields);
			}}
			onComplete={({ file, fields }) => {
				// optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
				// console.log("finished import of file", file, "with fields", fields);
				console.log("Attestation results: ", attestations);

				downloadAttestationCsv(attestations, includeQr);
			}}
			onClose={() => {
				closeModal();
			}}
		>
			{
				Object.values(schemaConfig.fields).filter((field) => !staticValues[field.name]).map((field) => (
					<ImporterField name={field.name} label={field.name.substring(0, 1).toUpperCase() + field.name.substring(1)} />
				))
			}
		</Importer>
		,
		importerDiv
	);

	importModal.style.display = "flex";
}

async function generateBulkAttestations(rows: any[], options: EasTicketCreationOptions, staticValues: {[name: string]: string}, attestationManager: EasTicketAttestation) {

	const attestations: ImportResult[] = [];

	for (const row of rows){

		const data = {
			...staticValues,
			...row
		};

		const attestation = await attestationManager.createEasAttestation(data, options);

		const magicLink = new URL(magicLinkBaseField.value);
		const query = new URLSearchParams();

		query.set("type", "eas");
		if (attestation.secret){
			query.set("secret", attestation.secret);
			query.set("id", row.commitment);
		}

		query.set("ticket", base64toBase64Url(attestationManager.getEncoded()));

		if (magicLinkTokenScriptField.value)
			query.set("scriptURI", magicLinkTokenScriptField.value);

		magicLink.search = query.toString();

		attestations.push({
			importedData: data,
			attestation,
			uid: attestationManager.getEasUid(),
			magicLink: magicLink.href,
		})
	}

	console.log(attestations);

	return attestations;
}

async function downloadAttestationCsv(result: ImportResult[], includeQr){

	const rows = [];

	for (const data of result){

		const row = {
			...data.importedData,
			magicLink: data.magicLink,
			attestation: JSON.stringify(data.attestation),
			uid: data.uid,
		}

		if (data.importedData.commitment)
			row.secret = data.attestation.secret;

		if (includeQr)
			row.qrCode = await createQrCodeDataUrl(data.magicLink);

		rows.push(row);
	}

	const csv = Papa.unparse(rows, {
		header: true,
		quotes: true
	});

	downloadCsv(csv, "attestations");
}

export function downloadCsv(content, filename) {
	// Create a blob
	var blob = new Blob([content], { type: "text/csv" });
	var url = URL.createObjectURL(blob);

	// Create a link to download it
	var pom = document.createElement('a');
	pom.href = url;
	pom.setAttribute('download',  filename + '-' + new Date().toLocaleDateString());
	pom.click();
}

export function closeModal(){
	importModal.style.display = "none";
	ReactDOM.unmountComponentAtNode(importerDiv);
}