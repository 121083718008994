import {
	EasTicketAttestation,
	EasTicketCreationOptions,
	SchemaField,
} from "@tokenscript/attestation/dist/eas/EasTicketAttestation";
import {BigNumber, ethers} from "ethers";
import {
	base64toBase64Url, hexStringToBase64,
	hexStringToUint8,
	uint8tohex
} from "@tokenscript/attestation/dist/libs/utils";
import {downloadCsv, openModal} from "./importer";
import {ABI_FIELD_TYPES, EAS_CHAIN_CONFIG, getEasConfig, RPC_CONFIG} from "./constants";
import {getPublicKeyConfig, getWallet} from "./wallet";
import {createQrCodeDataUrl} from "./util";
import {getSchemaUID} from "../node_modules/@ethereum-attestation-service/eas-sdk/dist/utils";
import crypto from "crypto";
import {arrayify, concat, entropyToMnemonic, hexDataSlice, keccak256} from "ethers/lib/utils";
import {KeyPair} from "@tokenscript/attestation/dist/libs/KeyPair";

type SchemaFieldConfig = (SchemaField & {
	isCommitment?: boolean, // Is the value hidden via pedersen commitment
	description?: string
});

export type GeneratorCreateOptions = EasTicketCreationOptions & {magicLinkBase?: string, magicLinkTokenScript?: string};

export interface SchemaConfig {
	config: {id: string, address: string, version: string, chainId: number}
	fields: SchemaFieldConfig[],
	options: GeneratorCreateOptions,
}

export interface FieldValues {
	[name: string]: {
		isStatic: boolean,
		value: string
	}
}

class CustomAttestationGenerator {

	// Sections
	private schemaDefSection = document.querySelector("#schema-definition") as HTMLDivElement;
	private createSection = document.querySelector("#create-attestation") as HTMLDivElement;
	private viewSection = document.querySelector("#attestation-view") as HTMLDivElement;

	// Schema config elements
	private easConfigSelect = document.querySelector("#eas-config-select") as HTMLSelectElement;

	private schemaTable = document.querySelector("#schema-table tbody");
	private recipientField = document.querySelector("#ethereum-address") as HTMLInputElement;
	private schemaField = document.querySelector("#schema-uid") as HTMLInputElement;
	private refField = document.querySelector("#ref-uid") as HTMLInputElement;

	private validityEnable = document.querySelector("#validity-enable") as HTMLInputElement;
	private validityFrom = document.querySelector("#validity-from") as HTMLInputElement;
	private validityTo = document.querySelector("#validity-to") as HTMLInputElement;

	private magicLinkBaseField = document.querySelector("#magic-link-base") as HTMLInputElement;
	private magicLinkTokenScriptField = document.querySelector("#magic-link-tokenscript") as HTMLInputElement;

	// Attestation create elements
	private inputTable = document.querySelector("#create-table-body") as HTMLTableSectionElement;

	// Output elements
	private schemaOutput = document.querySelector("#output-schema") as HTMLTextAreaElement;
	private attestationOutput = document.querySelector("#output-attestation") as HTMLTextAreaElement;
	private uuidOutput = document.querySelector("#uuid") as HTMLInputElement;

	private magicLink = document.querySelector("#magic-link") as HTMLAnchorElement;
	private negotiatorConfig = document.querySelector("#negotiator-config") as HTMLPreElement;

	private currentSchema?: SchemaConfig;

	private fieldValues: FieldValues = {};

	constructor() {
		this.validityEnable.addEventListener("change",(event: Event) => {
			const elem = event.target as HTMLInputElement;
			this.validityToggle(elem.checked);
		});
		document.getElementById("add-field-btn").addEventListener("click", () => this.addSchemaTableRow());

		this.easConfigSelect.innerHTML = Object.entries(EAS_CHAIN_CONFIG).map((entry) => {
			return `<option value="${entry[0]}">${entry[1].name}</option>`;
		}).join("\n");

		this.loadFromUrl();
	}

	private loadFromUrl(){

		if (document.location.hash.length > 1){

			const query = new URLSearchParams(document.location.hash.substring(1));

			if (query.has("preset")){
				this.addDefaultFields();

				switch (query.get("preset")){
					case "ts-viewer":
						this.magicLinkBaseField.value = "https://viewer.tokenscript.org/";
						this.magicLinkTokenScriptField.value = "https://viewer.tokenscript.org/assets/tokenscripts/attestation.tsml";
						this.fieldValues.eventId.value = "devcon6";
						break;
				}

				this.saveSchema();
				return;
			}

			if (!query.has("schema") || !query.has("values"))
				return;

			try {
				this.currentSchema = JSON.parse(window.atob(query.get("schema"))) as SchemaConfig;
				this.fieldValues = JSON.parse(window.atob(query.get("values"))) as FieldValues;
			} catch (e){
				this.addDefaultFields();
				this.schemaDefSection.style.display = "block";
				return;
			}

			if (!this.currentSchema || !this.currentSchema.fields?.length) {
				this.addDefaultFields();
				this.schemaDefSection.style.display = "block";
				return;
			}

			for (let field of this.currentSchema.fields) {
				const {name, description, type, isCommitment} = field;
				this.addSchemaTableRow(name, description, type, isCommitment);
			}

			let userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
			userTimezoneOffset *= Math.sign(userTimezoneOffset);

			const validity = this.currentSchema.options.validity;

			if (validity){
				if (validity.from)
					this.validityFrom.valueAsNumber = new Date((validity.from * 1000) + userTimezoneOffset).getTime();

				if (validity.to)
					this.validityTo.valueAsNumber = new Date((validity.to * 1000) + userTimezoneOffset).getTime();

				this.validityEnable.checked = true;
				this.validityToggle(this.validityEnable.checked);
			}

			let chainId = "sepolia";

			if (this.currentSchema.config) {
				for (const id in EAS_CHAIN_CONFIG) {
					const config = EAS_CHAIN_CONFIG[id].eas;

					if (this.currentSchema.config.version == config.version &&
						this.currentSchema.config.chainId === config.chainId) {
						chainId = id;
						break;
					}
				}
			}

			this.easConfigSelect.value = chainId;

			if (this.currentSchema.options.magicLinkBase)
				this.magicLinkBaseField.value = this.currentSchema.options.magicLinkBase;

			this.magicLinkTokenScriptField.value = this.currentSchema.options.magicLinkTokenScript;

			if (this.currentSchema.options.schema)
				this.schemaField.value = this.currentSchema.options.schema;

			if (this.currentSchema.options.refUID)
				this.refField.value = this.currentSchema.options.refUID

			this.saveSchema()

		} else {
			this.addDefaultFields();
			this.schemaDefSection.style.display = "block";
		}
	}

	private updateURLHash(){

		const query = new URLSearchParams();

		query.set("schema", window.btoa(JSON.stringify(this.currentSchema)));
		query.set("values", window.btoa(JSON.stringify(this.fieldValues)));

		document.location.hash = query.toString();
	}

	private validityToggle(enabled: boolean){

		if (enabled){
			const now = new Date();
			let userTimezoneOffset = now.getTimezoneOffset() * 60000;
			userTimezoneOffset *= Math.sign(userTimezoneOffset);

			this.validityFrom.valueAsNumber = new Date(now.getTime() + userTimezoneOffset).getTime();
			this.validityFrom.removeAttribute("disabled");
			this.validityTo.valueAsNumber = new Date((now.getTime() + userTimezoneOffset) + 86400000).getTime();
			this.validityTo.removeAttribute("disabled");
		} else {
			this.validityFrom.value = "";
			this.validityFrom.setAttribute("disabled", "true");
			this.validityTo.value = "";
			this.validityTo.setAttribute("disabled", "true");
		}
	}

	private addDefaultFields(){
		// Load default fields
		this.addSchemaTableRow("eventId", "The identifier for the event. Allows issuing multiple events with the same private key.", "string", false);
		this.addSchemaTableRow("ticketId", "A unique value to identify a single ticket.", "string", false);
		this.addSchemaTableRow("ticketClass", "A numeric value from 1-256 to represent the ticket class or tier.", "uint8", false);
		this.addSchemaTableRow("commitment", "The email address of the user. This value is hidden in a pedersen commitment and used to verify ownership.", "bytes", true);
		this.fieldValues = {
			eventId: {
				isStatic: true,
				value: "6"
			},
			ticketId: {
				isStatic: false,
				value: "12345"
			},
			ticketClass: {
				isStatic: false,
				value: "2"
			},
			commitment: {
				isStatic: false,
				value: "test@test.com"
			}
		}

		this.easConfigSelect.value = "sepolia";
	}

	public addSchemaTableRow(name?: string, description?: string, fieldType?: string, isCommitment?: boolean) {

		const row = document.createElement("tr");

		row.innerHTML = `
			<td>
				<label class="form-label">Name
					<input class="field-name form-control" type="text" value="${name !== undefined ? name : ''}" onchange="agen.applySchemaUid();"/>
				</label>
			</td>
			<td>
				<label class="form-label">Description
					<input class="field-description form-control" type="text" value="${description !== undefined ? description : ''}"/>
				</label>
			</td>
			<td>
				<label class="form-label">Type
					<select class="field-type form-select" onchange="agen.applySchemaUid();">
					  ${Object.values(ABI_FIELD_TYPES).map((type: string, index) => 
						`<option value="${type}" ${fieldType === type || (!fieldType && index === 0) ? "selected" : ""}>${type}</option>`).join("\n")}
					</select>
				</label>
			</td>
			<td>
				<label class="form-check-label" title="Hide this value in a pedersen commitment">Commitment
					<input class="field-optional form-check-input" type="checkbox" ${isCommitment === true ? 'checked' : ''}/>
				</label>
			</td>
			<td>
				<button type="button" class="btn btn-danger" onclick="this.parentElement.parentElement.remove(); agen.applySchemaUid();" title="Remove row">X</button>
			</td>
		`;

		this.schemaTable.append(row);
		this.applySchemaUid();
	}

	private renderInputTable(){

		let table = "";

		for (const field of this.currentSchema.fields){

			table += `
				<tr>
					<td class="field-name-label">${field.name}</td>
					<td>
						<input class="field-value form-control" type="text" value="${this.fieldValues[field.name]?.value ?? ''}" onchange="agen.updateValues()"/>
						<small class="form-text text-muted" style="font-size: 10px;">${field.description ?? ''}</small>
					</td>
					<td style="text-align: center">
						<input class="field-static" type="checkbox" ${this.fieldValues[field.name]?.isStatic ? 'checked="checked"' : ''} onchange="agen.updateValues()"
								title="When checked this value will be used for all bulk generated attestations."/>
					</td>
				</tr>
			`;

		}

		this.inputTable.innerHTML = table;
	}

	public updateValues(){

		for (const row of this.inputTable.children){
			const name = row.querySelector(".field-name-label").innerHTML;
			const value = (row.querySelector(".field-value") as HTMLInputElement).value;
			const isStatic = (row.querySelector(".field-static") as HTMLInputElement).checked;

			this.fieldValues[name] = {isStatic, value};
		}

		this.updateURLHash();

	}

	public applySchemaUid(){

		const config = this.getSchemaInformation();
		const schemaStr = config.fields.map((field) => {
			return field.type + " " + field.name;
		}).join(",");

		(document.getElementById('schema-uid') as HTMLInputElement).value = getSchemaUID(schemaStr, "0x0000000000000000000000000000000000000000", true);
	}

	public saveSchema(){

		try {
			this.currentSchema = this.getSchemaInformation();
		} catch (e){
			alert(e);
			return;
		}

		this.renderInputTable();

		this.schemaDefSection.style.display = "none";
		this.createSection.style.display = "block";

		this.updateURLHash();
	}

	public editSchema(){
		this.schemaDefSection.style.display = "block";
		this.createSection.style.display = "none";
		this.viewSection.style.display = "none";
	}
	
	private getSchemaInformation(){

		const schemaFields: {[name: string]: SchemaFieldConfig} = {};

		const rows = this.schemaTable.querySelectorAll("tbody tr")

		for (let row of rows){

			const name = (row.querySelector(".field-name") as HTMLInputElement).value;

			if (!name)
				throw new Error("Name must be entered.");

			if (schemaFields[name])
				throw new Error("Duplicate field name, all names must be unique.");

			schemaFields[name] = {
				name,
				description: (row.querySelector('.field-description') as HTMLInputElement).value,
				type: (row.querySelector('.field-type') as HTMLSelectElement).value,
				isCommitment: (row.querySelector('.field-optional') as HTMLInputElement).checked
			};
		}

		let validity: null|{from: number, to: number} = null;

		if (this.validityEnable.checked){
			let userTimezoneOffset = new Date().getTimezoneOffset() * 60000;

			const from = Math.round((this.validityFrom.valueAsNumber + userTimezoneOffset) / 1000);
			const to = Math.round((this.validityTo.valueAsNumber + userTimezoneOffset) / 1000);

			validity = {from, to};
		}

		/*let commitment = null

		if (this.commitmentEnable.checked){
			schemaFields["commitment"] = {
				name: "commitment",
				type: "bytes",
				isCommitment: true
			}
			fieldValues["commitment"] = commitmentValue.value;

			commitment = this.commitmentValue.value;
		}*/

		//const recipient = this.recipientField.value;
		//const schema = this.schemaField.value;
		const refUID = this.refField.value;
		const magicLinkBase = this.magicLinkBaseField.value;
		const magicLinkTokenScript = this.magicLinkTokenScriptField.value;

		const options: GeneratorCreateOptions = {
			validity,
			//schema,
			refUID,
			magicLinkBase,
			magicLinkTokenScript
		};

		const config = getEasConfig(this.easConfigSelect.value).eas;

		return {config, fields: Object.values(schemaFields), options};
	}

	public generateKey(){

		if (!confirm("Are you sure? This will replace the current private key, write it down if you need it!"))
			return;

		let entropy: Uint8Array = crypto.randomBytes(16);

		// Extra entropy
		entropy = arrayify(hexDataSlice(keccak256(concat([entropy, crypto.randomBytes(16)])), 0, 16));

		const mnemonic = entropyToMnemonic(entropy);
		const wallet = ethers.Wallet.fromMnemonic(mnemonic);

		const privKeyInput = document.querySelector("#priv-key") as HTMLTextAreaElement;

		privKeyInput.innerText = wallet.privateKey;

		alert("Please write down the private key before issuing attestations. It is not saved when refreshing the page!");
	}

	private getMappedValues(staticFields?: boolean){
		const fieldValues = {};
		for (const name in this.fieldValues){
			if (staticFields === undefined || staticFields === this.fieldValues[name].isStatic)
				fieldValues[name] = this.fieldValues[name].value;
		}
		return fieldValues
	}

	public async generateAttestation(useMetamask?: boolean){

		try {

			const wallet = await getWallet(this.currentSchema.config.chainId, useMetamask);

			const attestationManager = new EasTicketAttestation(
				{fields : this.currentSchema.fields},
				{
					EASconfig: this.currentSchema.config,
					signer: wallet
				}
			)

			this.currentSchema.options.recipient = this.recipientField.value;

			await attestationManager.createEasAttestation(this.getMappedValues(), this.currentSchema.options);

			this.renderAttestationView(attestationManager);

		} catch (e){
			console.error(e);
			alert(e.message);
		}

	}

	public async loadFromEncoded(){

		const base64Str = prompt("Please enter encoded attestation string:");

		try {
			const wallet = await getWallet(this.currentSchema.config.chainId);

			const attestationManager = new EasTicketAttestation(
				{fields : this.currentSchema.fields},
				{
					EASconfig: this.currentSchema.config,
					signer: wallet
				},
				RPC_CONFIG,
				{
					"": KeyPair.publicFromBase64orPEM("MIIBMzCB7AYHKoZIzj0CATCB4AIBATAsBgcqhkjOPQEBAiEA/////////////////////////////////////v///C8wRAQgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHBEEEeb5mfvncu6xVoGKVzocLBwKb/NstzijZWfKBWxb4F5hIOtp3JqPEZV2k+/wOEQio/Re0SKaFVBmcR9CP+xDUuAIhAP////////////////////66rtzmr0igO7/SXozQNkFBAgEBA0IABGpZjkqaWTikeOtxyfACQir7GtkMzCHaMxkBqlTM0YtDVnB62NzBccLKtEazkzRnvX65y+GmSzdRhgVMPE9ACww=")
				}
			);

			//const attestation = JSON.parse(this.attestationOutput.value);

			attestationManager.loadFromEncoded(base64Str);

			await attestationManager.validateEasAttestation();

			await this.renderAttestationView(attestationManager);

			return attestationManager;

		} catch (e){
			console.error(e);
			alert(e.message);
		}
	}

	public async loadAttestation(){
		try {
			const wallet = await getWallet(this.currentSchema.config.chainId);

			const attestationManager = new EasTicketAttestation(
				{fields : this.currentSchema.fields},
				{
					EASconfig: this.currentSchema.config,
					signer: wallet
				}
			);

			const attestation = JSON.parse(this.attestationOutput.value);

			attestationManager.loadEasAttestation(attestation.sig, getPublicKeyConfig());

			await attestationManager.validateEasAttestation();

			await this.renderAttestationView(attestationManager);

		} catch (e){
			console.error(e);
			alert(e.message);
		}
	}

	private async renderAttestationView(attestationManager: EasTicketAttestation){

		const attestation = attestationManager.getEasJson();

		this.schemaOutput.value = JSON.stringify(this.currentSchema.fields, null, 2);
		this.attestationOutput.value = JSON.stringify(attestation, null, 2);
		this.uuidOutput.value = attestationManager.getEasUid();

		// Render QR codes

		// Default EAS URL encoding
		const qrStringVal = attestationManager.getEncoded();
		await this.renderQrCode("qr", qrStringVal)

		// ASN Decimal
		let asnEncoded = attestationManager.getAsnEncoded(true);
		const asnDecimal = BigInt("0x" + uint8tohex(new Uint8Array(asnEncoded))).toString(10);
		await this.renderQrCode("asn-qr", asnDecimal)

		// Render magic link
		const url = new URL(this.magicLinkBaseField.value);
		const queryParams = new URLSearchParams();

		let commitmentValue;
		for (const field of this.currentSchema.fields){
			if (field.isCommitment){
				commitmentValue = this.fieldValues[field.name].value;
			}
		}

		queryParams.set("type", "eas");
		if (commitmentValue) {
			queryParams.set("id", commitmentValue);
			queryParams.set("secret", attestation.secret);
		}

		queryParams.set("ticket", base64toBase64Url(qrStringVal));

		if (this.magicLinkTokenScriptField.value)
			queryParams.set("scriptURI", this.magicLinkTokenScriptField.value);

		url.search = queryParams.toString();
		this.magicLink.href = url.toString();


		// Render Token Negotiator config snippet
		const json: any = {};

		json.tokenOrigin = this.magicLinkBaseField.value;
		json.eas = {
			config: this.currentSchema.config
		};

		const data = attestationManager.getAttestationData();
		const eventId = data.eventId ?? data.devconId ?? "";
		const key = getPublicKeyConfig()[""];

		json.base64senderPublicKeys = {
			[eventId]: hexStringToBase64(key.getAsnDerPublic())
		}

		this.negotiatorConfig.innerText = JSON.stringify(json, null, 2);

		document.getElementById("hex-pubkey").innerText = key.getPublicKeyAsHexStr();
		document.getElementById("eth-address").innerText = key.getAddress();

		this.viewSection.style.display = "block";
	}

	private async renderQrCode(domId: string, value: string){

		const qrImage = document.getElementById(domId + "-image") as HTMLImageElement;
		const qrText = document.getElementById(domId + "-string");

		qrImage.src = await createQrCodeDataUrl(value);
		qrText.innerText = value;
	}

	public downloadCsvTemplate(){

		// Don't include static fields in the template
		const csv = Object.keys(this.getMappedValues(false)).join(", ");

		downloadCsv(csv, "attestation-template");
	}

	public showBulkImportModal(){
		openModal(this.currentSchema, this.getMappedValues(true), (document.getElementById("bulk-include-qr") as HTMLInputElement).checked)
	}

	public async validateAttestation(){

		const fields = JSON.parse(this.schemaOutput.value);
		const attest = JSON.parse(this.attestationOutput.value);

		try {
			if (!fields || !attest)
				throw new Error("Schema and EAS attestation JSON must be provided");

			const attestationManager = new EasTicketAttestation(
				{fields: Object.values(fields)},
				undefined,
				RPC_CONFIG,
				getPublicKeyConfig()
			);

			attestationManager.loadEasAttestation(attest.sig);

			await attestationManager.validateEasAttestation();

			alert("Attestation successfully validated!");

		} catch (e){
			console.error(e);
			alert(e.message);
		}

	}

	public async validateEncoded(type: "normal" | "asn" = "normal"){

		try {

			const fields = JSON.parse(this.schemaOutput.value);

			const attestationManager = new EasTicketAttestation(
				{fields: Object.values(fields)},
				undefined,
				RPC_CONFIG,
				getPublicKeyConfig()
			);

			if (type === "asn") {

				const asnDecimal = document.getElementById("asn-qr-string").innerText;

				const uintarray = hexStringToUint8(BigNumber.from(asnDecimal).toHexString());

				attestationManager.loadAsnEncoded(uintarray, undefined, true);

			} else {
				const b64Encoded = document.getElementById("qr-string").innerText;

				attestationManager.loadFromEncoded(b64Encoded);
			}

			await attestationManager.validateEasAttestation();

			alert("Attestation successfully validated!");

		} catch (e){
			console.error(e);
			alert(e.message);
		}

	}

	public async revokeAttestation(){

		const fields = JSON.parse(this.schemaOutput.value);
		const attest = JSON.parse(this.attestationOutput.value);

		try {
			if (!fields || !attest)
				throw new Error("Schema and EAS attestation JSON must be provided");

			const config = {
				address: attest.sig.domain.verifyingContract,
				version: attest.sig.domain.version,
				chainId: attest.sig.domain.chainId
			};

			const attestationManager = new EasTicketAttestation(
				{fields: Object.values(fields)},
				{
					EASconfig: config,
					signer: await getWallet(config.chainId)
				}
			);

			// attestationManager.loadEasAttestation(attest.sig, getPublicKeyConfig());

			if (!confirm("You must have gas on sepolia network to process this transaction, click ok to proceed."))
				return;

			await attestationManager.revokeEasAttestation(this.uuidOutput.value);

			alert("Attestation successfully revoked!");

		} catch (e){
			console.error(e);
			alert(e.message);
		}
	}
}

declare global {
	interface Window {
		agen: any
	}
}

window.agen = new CustomAttestationGenerator();

