
export const EAS_CHAIN_CONFIG = {
	"sepolia": {
		name: "Sepolia v0.26",
		eas: {
			address: "0xC2679fBD37d54388Ce493F1DB75320D236e1815e",
			version: "0.26",
			chainId: 11155111,
		}
	},
	"arbitrum": {
		name: "Arbitrum 0.26",
		eas: {
			address: "0xbD75f629A22Dc1ceD33dDA0b68c546A1c035c458",
			version: "0.26",
			chainId: 42161
		}
	},
	"mainnet": {
		name: "Ethereum v0.26",
		eas: {
			address: "0xA1207F3BBa224E2c9c3c6D5aF63D0eb1582Ce587",
			version: "0.26",
			chainId: 1,
		}
	}
}

export const RPC_CONFIG = {
	1: "https://eth-mainnet.g.alchemy.com/v2/2bJxn0VGXp9U5EOfA6CoMGU-rrd-BIIT",
	11155111: "https://sepolia.infura.io/v3/9f79b2f9274344af90b8d4e244b580ef",
	42161: 'https://arb1.arbitrum.io/rpc'
}

export function getEasConfig(chain: string){
	return EAS_CHAIN_CONFIG[chain];
}

export enum ABI_FIELD_TYPES {
	bool = 'bool',
	uint8 = 'uint8',
	uint16 = 'uint16',
	uint32 = 'uint32',
	uint64 = 'uint64',
	uint128 = 'uint128',
	uint256 = 'uint256',
	address = 'address',
	string = 'string',
	bytes = 'bytes',
	bytes32 = 'bytes32'
}